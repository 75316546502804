<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <stats-card
          title="1 618 €"
          sub-title="CA De La Veille"
          type="warning"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          title="851 €"
          sub-title="CA du jour"
          type="warning"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          title="28 936 €"
          sub-title="CA du mois"
          type="warning"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-chart" data="orange">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6 text-left">
              <h2 class="card-title">Stats</h2>
              <h5 class="card-category" style="color:#000">du jour</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :gradient-colors="bigLineChart.gradientColors"
              :gradient-stops="bigLineChart.gradientStops"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <card>
        <base-button round type="warning" class="btn-just-icon mx-auto d-block">Appel à facture</base-button>
      </card>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h6 class="title d-inline">Campagnes shootées</h6>
          </div>
          <div class="card-body">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td>
                    <p class="title">Mutuelle Santiane Senior / sb-freedays_Mutuelle Santiane senior.MY DEAL_Visuel43</p>
                    <p class="text-muted">#219 - Envoyée le 15 janvier 2020, 10:04</p>
							      <p><a href="" style="color:#fff">Rapport</a> - <a href="" style="color:#fff">Apperçu</a> - <a href="" style="color:#fff">Plus &#9662;</a></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="title">Mutuelle Santiane Senior / sb-freedays_Mutuelle Santiane senior.MY DEAL_Visuel43</p>
                    <p class="text-muted">#219 - Envoyée le 15 janvier 2020, 10:04</p>
							      <p><a href="" style="color:#fff">Rapport</a> - <a href="" style="color:#fff">Apperçu</a> - <a href="" style="color:#fff">Plus &#9662;</a></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="title">Mutuelle Santiane Senior / sb-freedays_Mutuelle Santiane senior.MY DEAL_Visuel43</p>
                    <p class="text-muted">#219 - Envoyée le 15 janvier 2020, 10:04</p>
							      <p><a href="" style="color:#fff">Rapport</a> - <a href="" style="color:#fff">Apperçu</a> - <a href="" style="color:#fff">Plus &#9662;</a></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import LineChart from '@/components/Charts/LineChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';

let bigChartData = [
  [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
  [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
  [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
]
let bigChartLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

export default {
  components: {
    StatsCard,
    LineChart
  },
  data(){
    return {
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: bigChartData[0]
          }],
          labels: bigChartLabels
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
    }
  },
  computed: {
    bigLineChartCategories() {
      return [{ name: 'Gold', icon: 'tim-icons icon-single-02' }, { name: 'Shoots', icon: 'tim-icons icon-gift-2' }, { name: 'Bronze', icon: 'tim-icons icon-tap-02' }];
    }
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    }
  },
  mounted() {
    this.initBigChart(0);
  }
};
</script>
<style></style>